<template>
  <transition
    name="slide"
    enter-active-class="slide-enter-active"
    leave-active-class="slide-leave-active"
    enter-from-class="slide-enter-from"
    enter-to-class="slide-enter-to"
    leave-to-class="slide-leave-to"
  >
    <div v-if="toast.show" :class="`toast-container ${toast.type}`">
      <Vue3Lottie
          class="toast-animation"
          :animationData="animationData"
          :loop="true"
          height="auto"
          :speed="1"
      />
      <div class="toast-text">
        <div class="main-text">{{ toast.mainText }}</div>
        <div class="secondary-text">{{ toast.secondaryText }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import animationData from '@public/resources/lottie/stowyLoader.json';
import { mapState } from 'vuex';

export default {
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      isVisible: false,
      animationData,
    };
  },
  computed: {
    ...mapState({
      toast: state => state.toast
    })
  },

  watch: {
    toast: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        console.log("Toast updated: ", newValue, oldValue);
        if (newValue.show) {
          console.log("Condition 'newValue.show' is true. Showing toast.");
          this.showToast();
        }
        if (oldValue && oldValue.type === 'info' && newValue.type !== 'info') {
          console.log("Condition 'oldValue.type === info && newValue.type !== info' is true. Hiding toast after delay.", oldValue.type, newValue.type);
          this.hideToastAfterDelay();
        }
      }
    }
  },

  methods: {
    showToast() {
      console.log("Showing toast of type: ", this.toast.type);
      this.isVisible = true;
      if (this.toast.type !== 'info') {
        console.log("Toast type is not 'info'. Hiding after delay.");
        this.hideToastAfterDelay();
      } else {
        console.log("Toast type is 'info'. It will remain visible.");
      }
    },
    hideToastAfterDelay() {
      if (this.toast.type === 'info') {
        console.log("Toast type is 'info'. Not hiding automatically.");
        return;
      }
      console.log("Hiding toast after delay");
      setTimeout(() => {
        if (this.toast.type !== 'info') {
          console.log("Toast hidden");
          this.isVisible = false;
          this.$store.commit('hideToast');
        } else {
          console.log("Toast type changed to 'info'. Not hiding.");
        }
      }, 5000);
    },
  },

  created() {
    console.log("Toast component created.");
  },
  mounted() {
    console.log("Toast component mounted.");
  }
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-enter-from, .slide-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.slide-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.toast-animation {
  flex: 1;
  max-width:140px;
}

.toast-text {
  color: white;
  text-shadow: 1px 1px 2px black;
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
}

.main-text {
  font-size: min(6.5vw,32.5px);
}

.secondary-text {
  font-size: min(3.9vw,20px);
}

.success {
  background-color: #bdd96a;
}

.info {
  background-color: #4da0ff;
}

.error {
  background-color: #ee7d15;
}
</style>