<template>
  <div class="form-field">
    <div class="input-wrapper" :class="{ focused: isFocused, error: hasError }">
      <!-- Si multiline est true, on affiche un textarea -->
      <template v-if="multiline">
        <textarea
          :placeholder="placeholder"
          v-model="fieldValue"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
        ></textarea>
      </template>
      <!-- Sinon, on affiche un input classique -->
      <template v-else>
        <input
          :type="inputType"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :class="{ focused: isFocused, error: hasError }"
          v-model="fieldValue"
          @input="handleInput"
          @focus="handleFocus"
          @blur="handleBlur"
        />
      </template>
    </div>
    <!-- Afficher le bouton pour afficher/masquer le mot de passe uniquement pour un input -->
    <div v-if="!multiline" class="input-addon">
      <button
        v-if="isPasswordType && fieldValue"
        class="toggle-password"
        @click="togglePasswordVisibility"
      >
        <img
          :src="
            passwordVisible
              ? require('@app-assets/fa-eye-slash.png')
              : require('@app-assets/fa-eye.png')
          "
          alt="Toggle Password Visibility"
        />
      </button>
    </div>
  </div>
  <div v-if="showValidationMessage" class="validation-zone">
    <img src="@app-assets/fa-flag.png" alt="Warning Flag" class="flag" />
    <p class="validation-message">
      {{ validationMessage }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'FormField',
    props: {
      type: {
        type: String,
        default: 'text',
      },
      placeholder: {
        type: String,
        default: '',
      },
      validationMessage: {
        type: String,
        default: '',
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        type: String,
        default: '',
      },
      autocomplete: {
        type: String,
        default: '',
      },
      // Nouvelle prop pour afficher un textarea
      multiline: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['input', 'update:modelValue'],
    data() {
      return {
        fieldValue: this.modelValue,
        inputType: this.type,
        passwordVisible: false,
        isFocused: false,
      }
    },
    computed: {
      isPasswordType() {
        return this.type === 'password'
      },
      showValidationMessage() {
        return this.validationMessage !== '' && this.hasError
      },
    },
    methods: {
      handleInput() {
        this.$emit('input', this.fieldValue)
        this.$emit('update:modelValue', this.fieldValue)
      },
      handleFocus() {
        this.isFocused = true
      },
      handleBlur() {
        this.isFocused = false
      },
      togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible
        this.inputType = this.passwordVisible ? 'text' : 'password'
      },
    },
    watch: {
      modelValue: {
        immediate: true,
        handler(newValue) {
          this.fieldValue = newValue
        },
      },
    },
  }
</script>

<style scoped>
  .form-field {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
  }

  .input-wrapper::before {
    content: '';
    border-radius: min(2.93vw, 15px);
    position: absolute;
    top: min(3.125vw, 16px);
    height: calc(100% - min(25.5px, 5vw));
    left: 0;
    right: 0;
  }

  .input-wrapper.focused::before {
    background: linear-gradient(90deg, #4da0ff 0%, #7b4fd4 100%);
  }

  .input-wrapper.error::before {
    background: linear-gradient(90deg, #d55e73 0%, #ee7d15 100%);
  }

  /* Styles communs pour input et textarea */
  input,
  textarea {
    padding: min(3.125vw, 16px) 0 min(3.125vw, 16px) min(2.93vw, 15px);
    margin: min(3.125vw, 16px) 0;
    box-sizing: border-box;
    background: white;
    box-shadow: 0px min(0.39vw, 2px) min(0.39vw, 2px) rgba(0, 0, 0, 0.25);
    border-radius: min(2.93vw, 15px);
    border: none;
    font-style: normal;
    font-family: 'Baloo Bhai 2';
    font-size: min(3.9vw, 20px);
    letter-spacing: min(0.1vw, 0.5px);
    font-weight: 500;
    color: rgba(96, 106, 134, 0.5);
    width: 100%;
    outline: none;
    position: relative;
  }

  /* Adaptation pour le textarea */
  textarea {
    resize: vertical;
    min-height: 100px;
  }

  input.focused,
  input.error,
  textarea.focused,
  textarea.error {
    box-shadow: none;
  }

  input:not(:placeholder-shown),
  textarea:not(:placeholder-shown) {
    color: #4da0ff;
  }

  input.error:not(:placeholder-shown),
  textarea.error:not(:placeholder-shown) {
    color: #d55e73;
  }

  .input-addon {
    display: flex;
    align-items: center;
    position: absolute;
    right: min(0.49vw, 2.5px);
  }

  .validation-zone {
    margin: max(-1vw, -5px) 0 min(1vw, 5px) 0;
    display: flex;
    align-items: center;
  }

  .flag {
    width: min(2.24vw, 11.5px);
    height: min(2.34vw, 12px);
  }

  .validation-message {
    margin: min(-0.2vw, -1px) 0 min(-0.2vw, -1px) min(1.47vw, 7.5px);
    color: #d55e73;
  }

  .toggle-password {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: min(0.49vw, 2.5px);
    margin-right: min(0.49vw, 2.5px);
    padding: min(0.49vw, 2.5px);
  }

  .toggle-password img {
    width: min(3.02vw, 15.5px);
    height: min(2.34vw, 12px);
  }
</style>
