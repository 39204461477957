<template>
  <button :class="['action-button', buttonType, { 'async-processing': asyncFunctionOngoing }]" @click="handleClick" :disabled="asyncFunctionOngoing">
    <div v-if="asyncFunctionOngoing" class="spinner"></div>
    <!--<Vue3Lottie 
      v-if="asyncFunctionOngoing"
      class="spinner"
      :animationData="animationData"
      :loop="true"
      height="auto"
      :speed="1.1"
    />-->
    <span v-else class="button-text">{{ label }}</span>
  </button>
</template>

<script>
  import { Vue3Lottie } from 'vue3-lottie';
  import animationData from '@public/resources/lottie/stowyLoader.json';

  export default {
    name: "ActionButton",
    components: {
        Vue3Lottie,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      buttonType: { 
        type: String,
        default: "primary",
      },
      asyncFunctionOngoing: {
        type: Boolean,
        default: false,
      },
    }
  };
</script>

<style>

.action-button {
  border-radius: min(2.93vw, 15px);
  cursor: pointer;
  width:100%;
  font-style: normal;
  font-family: 'Baloo Bhai 2';
  border: none;
  letter-spacing: min(0.1vw, 0.5px);
  margin:min(3.125vw, 16px) 0;
  transition: transform 0.3s, box-shadow 0.3s;
  padding-left: min(1.95vw, 10px);
  padding-right: min(1.95vw, 10px);
  transition: transform 0.3s, box-shadow 0.3s, background 500ms ease-in-out;
}

.action-button.tilt {
  transform: rotate(-1deg);
}

.action-button.title-button {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
  height:min(23.43vw, 120px);
}

.action-button:focus {
  outline: none;
}

.action-button::-moz-focus-inner {
  border: 0;
}

.action-button:hover {
  transform: scale(1.02);
}

.action-button.primary,.action-button.secondary{
  box-shadow: 0 min(0.98vw, 5px) min(1.95vw, 10px) rgba(0, 0, 0, 0.15);
}

.action-button:active {
  transform: scale(0.98);
  box-shadow: 0 min(0.49vw, 2.5px) min(0.98vw, 5px) rgba(0, 0, 0, 0.12);
}

.action-button.primary {
  padding-top:min(4.125vw, 21px);
  padding-bottom:min(4.125vw, 21px);
  font-weight: 600;
  font-size: min(6.5vw,32.5px);
  background: linear-gradient(90deg, #7B51D4 0%, #85CDFE 100%);
  text-shadow: 0px min(0.2vw, 1px) min(0.39vw, 2px) rgba(0, 0, 0, 0.25);
  color: white;
}

.action-button.secondary {
  position:relative;
  padding-top:min(3.125vw, 16px);
  padding-bottom:min(3.125vw, 16px);
  font-weight: 500;
  font-size: min(5.5vw,22.5px);
  background: linear-gradient(90deg, rgba(123, 79, 212, 0.5) 0%, rgba(100, 119, 233, 0.5) 48.95%, rgba(77, 160, 255, 0.5) 100%);
  color:#A880FE;
}

.action-button.tertiary {
  background:none;
  margin: min(2.07vw, 10.5px) 0;
  position:relative;
  padding-top:0;
  padding-bottom:min(3.125vw, 16px);
  font-weight: 500;
  font-size: min(5.5vw,22.5px);
  color:#A880FE;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.action-button.secondary::before {
  border-radius: min(2.63vw, 13.5px);
  content: '';
  position: absolute;
  display: block;
  background:white;
  top:min(0.39vw, 2px);
  left:min(0.39vw, 2px);
  right:min(0.39vw, 2px);
  bottom:min(0.39vw, 2px);
}

.button-text {
  position: relative;
  z-index: 2;
}

.spinner {
  position:relative;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #4da0ff;
  -webkit-mask-image: url('~@common-assets/ring-resize.svg');
  mask-image: url('~@common-assets/ring-resize.svg');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-position: center;
  mask-position: center;
}

.action-button.async-processing {
  background: linear-gradient(90deg, #bbb 0%, #e6e6e6 100%);
  pointer-events: none;
  cursor: default;
}

.action-button:disabled {
  background: linear-gradient(90deg, #bbb 0%, #e6e6e6 100%);
  pointer-events: none;
  cursor: default;
}

</style>
