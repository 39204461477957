// logger.js
const isProduction = process.env.NODE_ENV === 'production';

const logger = {
  debug: (...args) => {
    if (!isProduction) console.debug(...args);
  },
  info: (...args) => {
    if (!isProduction) console.info(...args);
  },
  warn: (...args) => console.warn(...args),
  error: (...args) => console.error(...args),
};

export default logger;
