import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/app-check'
import logger from './logger'
import axios from 'axios'

// firebase init
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}
const fireApp = firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.app().functions('europe-west1')
// Initialisation différée de Firebase Analytics
let analytics = null

function initializeAnalytics() {
  if (!analytics) {
    analytics = firebase.analytics()
    console.log('Firebase Analytics initialized')
  }
}

function removeAnalytics() {
  if (analytics) {
    analytics.app
      .delete()
      .then(() => {
        analytics = null
        console.log('Firebase Analytics removed')
      })
      .catch((error) => {
        console.error('Error while deleting Firebase Analytics:', error)
      })
  }
}

const appCheckScript = document.createElement('script')
appCheckScript.innerHTML = 'self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;'
document.head.append(appCheckScript)
const appCheck = firebase.appCheck()

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  process.env.VUE_APP_FIREBASE_APP_CHECK_ID,
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
)

// collection references
const usersCollection = db.collection('users')
const customersCollection = db.collection('customers')

// Axios instance / Public API
let axiosInstance = null
let token = ''

auth.onAuthStateChanged((user) => {
  if (auth.currentUser) {
    auth.currentUser.getIdToken(true).then((newToken) => {
      token = newToken
      axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_FIREBASE_FUNCTIONS_ENDPOINT,
        timeout: 60000,
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      logger.debug('Axios with token', token)
    })
  }
})

async function post(url, body) {
  if (!axiosInstance) {
    throw new Error('Axios instance is not initialized.')
  }
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, body)
      .then((result) => resolve(result.data))
      .catch((error) => {
        if (error.response && error.response.data.message) {
          reject(error.response.data.message)
        } else {
          reject('An error occurred')
        }
      })
  })
}

/*
Used to re-sign in behaves of uid
 */
async function getToken(uid) {
  const result = await post('/api/token', { uid: uid })
  console.info('/token', result)
  return result
}

/*
Returns the /users node (profile) corresponding to that email
 */
async function processSignup(email, firstName, lastName, option) {
  const result = await post('/api/signup', {
    email: email,
    firstName: firstName,
    lastName: lastName,
    option: option,
  })
  console.info('/signup', result)
  return result
}

/*
Returns the checkout url for user payment or empty if option is 'free'
 */
async function processCheckout(email, option) {
  const result = await post('/api/checkout', {
    email: email,
    option: option,
  })
  console.info('/checkout', result)
  return result
}

/*
Returns 200 OK if deletion of uid succeeded
 */
async function processCleanUp(uid) {
  const result = await post('/api/cleanup', { uid: uid })
  console.info('/cleanup', result)
  return result
}

/*
Returns 200 OK if deletion of uid succeeded
 */
async function unsubscribeUser(uid) {
  const result = await post('/api/unsubscribe', { uid: uid })
  console.info('/unsubscribe', result)
  return result
}

/*
Returns the portal url for user subscription and payment method administration
 */
async function createPortalLink(uid) {
  const result = await post('/api/portal', {
    uid: uid,
  })
  console.info('/portal', result)
  return result
}

/*
Returns the /users node (profile) corresponding to that email
*/
async function hardProcessSignup(email, password, firstName, lastName, option) {
  try {
    let userCredential = null
    // Check if the current user is anonymous
    if (auth.currentUser && auth.currentUser.isAnonymous) {
      // Create email and password credential
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      )
      // Link the anonymous account to the email/password account
      userCredential = await auth.currentUser.linkWithCredential(credential)
      console.log('Successfully linked to an email/password account!')
    } else {
      //This case should not happen thanks to route setting, but we've got it covered "just in case"
      userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      )
      console.log('No anonymous user to link. Successfully registered!')
    }

    if (userCredential) {
      const user = userCredential.user
      console.log('user : ' + JSON.stringify(user))

      // Initial user data
      const userData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        name: `${email}`,
      }

      // If option is "free", add stripeRole: "free"
      if (option === 'free') {
        userData.stripeRole = 'free'
      }

      // Store additional profile information in Firestore
      await db.collection('users').doc(user.uid).set(userData, { merge: true }) // Using merge: true to update or create the document
    }

    return userCredential.user
  } catch (error) {
    console.log(error.message)
    throw error
  }
}

async function updatePassword(oldPassword, newPassword) {
  const user = auth.currentUser
  if (!user) {
    throw new Error("Pas d'utilisateur authentifié trouvé.")
  }

  try {
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      oldPassword
    )

    await user.reauthenticateWithCredential(credential)
    await user.updatePassword(newPassword)
    console.log('Le mot de passe a été mis à jour avec succès.')
  } catch (error) {
    console.error('Erreur lors de la mise à jour du mot de passe:', error)
    throw error
  }
}

async function addCharacterToUserList(uid, character) {
  const userRef = usersCollection.doc(uid)
  try {
    await db.runTransaction(async (transaction) => {
      const userDoc = await transaction.get(userRef)
      if (!userDoc.exists) throw new Error('The document does not exist!')
      const userData = userDoc.data()
      userData.charactersList = userData.charactersList || []
      if (userData.charactersList.length >= 5) {
        const error = new Error('The character list is full.')
        error.code = 'custom/too-many-characters'
        throw error
      }
      userData.charactersList.push(character)
      transaction.update(userRef, { charactersList: userData.charactersList })
    })
  } catch (error) {
    console.error('Error while adding a character:', error.message)
    throw error
  }
}

async function updateCharacterInUserList(
  uid,
  characterIndex,
  updatedCharacter
) {
  const userRef = usersCollection.doc(uid)
  try {
    const userDoc = await userRef.get()
    if (!userDoc.exists) throw new Error("User document doesn't exist!")
    const userData = userDoc.data()
    userData.charactersList[characterIndex] = updatedCharacter
    await userRef.update({ charactersList: userData.charactersList })
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du personnage dans la liste de l'utilisateur:",
      error
    )
    throw error
  }
}

async function deleteCharacterFromUserList(uid, characterIndex) {
  const userRef = usersCollection.doc(uid)
  try {
    const userDoc = await userRef.get()
    if (!userDoc.exists) throw new Error('Document does not exist!')
    const userData = userDoc.data()
    userData.charactersList.splice(characterIndex, 1)
    await userRef.update({ charactersList: userData.charactersList })
  } catch (error) {
    console.error('Error deleting character from user list:', error)
    throw error
  }
}

async function updateLastActiveCharacter(uid, lastActiveCharacterIndex) {
  const userRef = usersCollection.doc(uid)

  try {
    // On utilise directement update car on modifie un champ spécifique
    await userRef.update({
      lastActiveCharacter: lastActiveCharacterIndex,
    })
    console.log('Last active character index updated successfully.')
  } catch (error) {
    console.error('Error updating last active character index:', error)
    throw error
  }
}

async function getUniversesList(locale) {
  try {
    const universeRef = db.collection('universes').doc(locale)
    const doc = await universeRef.get()
    if (!doc.exists) {
      console.error('No document found for locale:', locale)
      throw new Error('Document not found.')
    }
    const content = doc.data().content
    return content
  } catch (error) {
    console.error('Error retrieving universes list:', error)
    throw error
  }
}

async function getStoryMetaData(storyId, locale) {
  try {
    const storyRef = db.collection('stories').doc(storyId + '-' + locale)
    const doc = await storyRef.get()
    if (!doc.exists) {
      console.error('No document found for locale:', locale)
      throw new Error('Document not found.')
    }
    const content = doc.data().meta
    return content
  } catch (error) {
    console.error('Error retrieving story meta data:', error)
    throw error
  }
}

async function deleteUser(uid) {
  const result = await post('/api/user/delete', { uid: uid })
  console.info('/delete', result)
  return result
}

export {
  db,
  auth,
  functions,
  analytics,
  initializeAnalytics,
  removeAnalytics,
  appCheck,
  usersCollection,
  customersCollection,
  getToken,
  hardProcessSignup,
  processCheckout,
  processCleanUp,
  processSignup,
  createPortalLink,
  updatePassword,
  addCharacterToUserList,
  updateCharacterInUserList,
  deleteCharacterFromUserList,
  updateLastActiveCharacter,
  getUniversesList,
  getStoryMetaData,
  unsubscribeUser,
  deleteUser,
}
