<template>
    <div :class="options.classes">
      {{ typedText }}
    </div>
</template>
  
  <script>
  export default {
    name: 'Typed',
    props: {
      options: {
        type: Object,
        required: true,
        default: () => ({
          strings: [],
          typeSpeed: 50,
          deleteSpeed: 50,
          startDelay: 0,
          loop: false,
          autoDelete: false, 
          showCursor: true,
          classes: ''
        })
      }
    },
    data() {
      return {
        typedText: '',
        currentCharIndex: 0,
        currentStringIndex: 0,
        isDeleting: false,
      };
    },
    mounted() {
      this.processTyping();
    },
    methods: {
      processTyping() {
        if (this.options.startDelay && !this.isDeleting) {
          setTimeout(this.typeCharacter, this.options.startDelay);
        } else {
          this.typeCharacter();
        }
      },
      typeCharacter() {
        const stringToType = this.options.strings[this.currentStringIndex];
  
        if (!this.isDeleting) {
          this.typedText += stringToType.charAt(this.currentCharIndex);
          this.currentCharIndex++;
          if (this.currentCharIndex === stringToType.length) {
            if (this.options.autoDelete) {
              setTimeout(() => {
                this.isDeleting = true;
                this.typeCharacter();
              }, 2000); 
            } else {
              this.moveToNextString();
            }
          } else {
            setTimeout(this.typeCharacter, this.options.typeSpeed);
          }
        } else {
          this.typedText = stringToType.substring(0, this.currentCharIndex - 1);
          this.currentCharIndex--;
          if (this.currentCharIndex < 0) {
            this.isDeleting = false;
            this.moveToNextString();
          } else {
            setTimeout(this.typeCharacter, this.options.deleteSpeed);
          }
        }
      },
      moveToNextString() {
        this.currentStringIndex = (this.currentStringIndex + 1) % this.options.strings.length;
        this.currentCharIndex = 0;
        if (!this.options.loop && this.currentStringIndex === 0) {
          return; 
        }

        setTimeout(() => {
          this.isDeleting = false;
          this.processTyping();
        }, 500); 
      }

    }
  };
  </script>
  