// store/index.js
import { createStore } from 'vuex'
import * as fb from '@common/firebase'
import logger from '@common/logger'
import { auth, usersCollection, analytics } from '@common/firebase'

const store = createStore({
  state: {
    userSession: {
      auth: {
        record: null,
        signingIn: false,
        signInSuccessful: false,
      },
      profile: null,
      checkout: {
        link: null,
      },
      payment: {
        awaiting: false,
        pending: false,
        done: false,
      },
      portal: {
        url: null,
      },
      cookies: {
        acted: null,
        essentials: true,
        analytics: true,
        ads: true,
      },
      unsubscribeFunction: null,
      error: null,
      success: null,
    },
    showMenu: true,
    menuDisplayChecked: false,
    toast: {
      show: false,
      type: '', // 'success', 'info', 'error'
      mainText: '',
      secondaryText: '',
    },
    storiesListLoading: false,
    isOnline: true,
  },
  mutations: {
    resetCookies(state) {
      state.userSession.cookies.acted = false
      state.userSession.cookies.essentials = true
      state.userSession.cookies.analytics = false
      state.userSession.cookies.ads = false
    },
    setSignInSuccessful(state, val) {
      state.userSession.auth.signInSuccessful = val
    },
    setNetworkStatus(state, status) {
      state.isOnline = status
    },
    setStoriesListLoading(state, val) {
      state.storiesListLoading = val
    },
    setAuthUser(state, val) {
      state.userSession.auth.record = val
      logger.debug('AuthUser:', state.userSession.auth.record)
    },
    setLastActiveCharacter(state, val) {
      state.userSession.profile.lastActiveCharacter = val
    },
    setUserProfile(state, val) {
      state.userSession.profile = val
      logger.debug('UserProfile:', val)
    },
    setUserError(state, val) {
      state.userSession.error = val
      logger.debug('UserError', val)
    },
    setUserCheckoutLink(state, val) {
      state.userSession.checkout.link = val
    },
    setPaymentAwaiting(state, val) {
      state.userSession.payment.awaiting = val
      state.userSession.payment.pending = false
      state.userSession.payment.done = false
    },
    setPaymentPending(state, val) {
      state.userSession.payment.awaiting = false
      state.userSession.payment.pending = val
      state.userSession.payment.done = false
    },
    setPaymentDone(state, val) {
      state.userSession.payment.awaiting = false
      state.userSession.payment.pending = false
      state.userSession.payment.done = val
    },
    setPortalLink(state, val) {
      state.userSession.portal.url = val
    },
    setSessionSigningIn(state, val) {
      state.userSession.auth.signingIn = val
    },
    setSuccessMessage(state, val) {
      state.userSession.success = val
    },
    setUnsubscribeFunction(state, val) {
      state.unsubscribeFunction = val
    },
    setShowMenu(state, show) {
      state.showMenu = show
      state.menuDisplayChecked = true
    },
    showToast(state, { type, mainText, secondaryText }) {
      state.toast.show = true
      state.toast.type = type
      state.toast.mainText = mainText
      state.toast.secondaryText = secondaryText
    },
    hideToast(state) {
      state.toast.show = false
    },
  },

  actions: {
    logEvent({ state }, { eventName, params = {}, source = 'app_' }) {
      const eventConfig = {
        story_rating: {
          firebaseAnalytics: false,
          firestore: true,
          hubspot: false,
          log: 'The user has rated story Id {storyId}',
        },
        feedback_submitted: {
          firebaseAnalytics: false,
          firestore: true,
          hubspot: true,
          log: '{feedback}',
        },
        launch_app: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user launched the application.',
        },
        click_used_to: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user clicked on 'I am used to it'.",
        },
        click_go_back: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user clicked on 'Go back'.",
        },
        click_go_back_to_home: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user clicked on 'Go back' (to Home).",
        },
        click_help: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user clicked on 'Need help'.",
        },
        validate_email: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user validated their email address : {email}.',
        },
        email_error: {
          firebaseAnalytics: false,
          firestore: true,
          hubspot: false,
          log: 'The user encountered an error with the email address: {error_code} {wrong_email}.',
        },
        validate_password_and_login: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: true,
          log: 'The user validated their password and logged in.',
        },
        password_error: {
          firebaseAnalytics: false,
          firestore: true,
          hubspot: false,
          log: 'The user encountered an error with the password: {error_code}.',
        },
        click_discover: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user clicked on 'Discover'.",
        },
        discovery_complete: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user completed the discovery process.',
        },
        skip_discovery: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user skipped the discovery.',
        },
        create_character: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user created their first character.',
        },
        create_additional_character: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user added an additional character.',
        },
        delete_character: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user has deleted a character.',
        },
        enter_name_gender: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user entered the name '{first_name}' and selected the gender '{gender}' for the character.",
        },
        open_passport: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user opened the passport.',
        },
        open_multikids_popup: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user opened the multi-kids popup.',
        },
        close_multikids_popup: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user closed the multi-kids popup.',
        },
        click_edit_character_design: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user clicked on 'Edit Appearance'.",
        },
        update_skin_color: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the skin color to {skin_color_id}.',
        },
        update_eye_shape: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the eye shape to {eye_shape_id}.',
        },
        update_eye_color: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the eye color to {eye_color_id}.',
        },
        update_hair_color: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the hair color to {hair_color_id}.',
        },
        update_hair_shape: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the hair shape to {hair_shape_id}.',
        },
        update_nose_shape: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the nose shape to {nose_id}.',
        },
        update_glasses_shape: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the glasses shape to {glasses_shape_id}.',
        },
        update_glasses_color: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the glasses color to {glasses_color_id}.',
        },
        update_eyebrow: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the eyebrow to {eyebrow_id}.',
        },
        update_face_attributes: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the face attributes to {face_attributes_id}.',
        },
        update_hat: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user updated the hat to {hat_id}.',
        },
        back_to_character_selection: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user returned to the character selection screen.',
        },
        click_its_me: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: "The user clicked 'It's me'.",
        },
        open_solo_kid_popup_anonymous: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user opened the Solo Kid popup (anonymous user).',
        },
        open_settings_popup_logged_in: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user opened the settings popup (logged in).',
        },
        logout: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user logged out.',
        },
        delete_info_anonymous: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user deleted their information (anonymous user).',
        },
        //not implemented yet because la flemme
        link_anonymous_to_paid: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user linked their anonymous account (old uid: {old_uid}) to their paid account (new uid: {new_uid}).',
        },
        close_settings_popup: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user closed the settings popup.',
        },
        open_universe: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user opened the universe {universe_id}.',
        },
        close_universe: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user closed the universe.',
        },
        open_story_summary: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user opened the story summary with id {story_id}.',
        },
        close_story_summary: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user closed the story summary.',
        },
        launch_story: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user launched the story with id {story_id}.',
        },
        return_from_story: {
          firebaseAnalytics: true,
          firestore: true,
          hubspot: false,
          log: 'The user returned to the app from a completed story with id {story_id}. Reading session duration: {reading_session_duration} minutes.',
        },
      }

      const config = eventConfig[eventName]

      if (!config) {
        console.error(`Event name ${eventName} is not configured.`)
        return
      }

      const logMessage = config.log.replace(
        /{(\w+)}/g,
        (_, key) => params[key] || ''
      )

      const timestamp = new Date()
      const logVersion = '1.0'
      const userProfile = state.userSession.profile
      const userIdentifier = userProfile
        ? `${userProfile.firstName} ${userProfile.lastName}`
        : state.userSession.auth.record
          ? state.userSession.auth.record.uid
          : 'anonymous'

      params.identifier = userIdentifier

      if (config.firebaseAnalytics && analytics) {
        analytics.logEvent(eventName, { ...params, source })
      }

      if (config.firestore) {
        if (state.userSession.auth.record) {
          const userId = state.userSession.auth.record.uid
          const maxTimestamp = 8640000000000000 // Maximum value for a timestamp in js
          const reverseTimestamp = (maxTimestamp - timestamp.getTime())
            .toString()
            .slice(-12) // Keep 12 last digits of reverse timecode for alphabetical sorting
          const shortTimestamp = timestamp
            .toISOString()
            .replace(/[-:.TZ]/g, '')
            .slice(2, 12) // Timecode is shortened
          const eventShortName =
            eventName.length > 10 ? eventName.slice(0, 10) : eventName // Event name is shortened
          const documentId = `${reverseTimestamp}_${shortTimestamp}_${eventShortName}`
          fb.usersCollection
            .doc(userId)
            .collection('logs')
            .doc(documentId)
            .set({
              event: eventName,
              params: { ...params, source },
              log: logMessage,
              timestamp,
              version: logVersion,
            })
        } else {
          console.error('User is not authenticated.')
        }
      }

      if (config.hubspot) {
        // Call hubspot example
        // hubspot.trackEvent(eventName, { ...params, source });
      }

      console.debug(logMessage)
    },

    initializeAnalytics() {
      fb.initializeAnalytics()
    },

    removeAnalytics() {
      fb.removeAnalytics()
    },

    async loadUserProfile({ commit, dispatch, state }) {
      if (state.userSession.auth.record) {
        const userId = state.userSession.auth.record.uid
        try {
          const userProfileSnapshot = await usersCollection.doc(userId).get()
          if (userProfileSnapshot.exists) {
            commit('setUserProfile', userProfileSnapshot.data())
          } else {
            console.error('UserProfile does not exist')
            await dispatch('logout')
          }
        } catch (error) {
          console.error('Error loading user profile:', error)
          commit('setUserError', error.message)
        }
      } else {
        console.error('No authenticated user')
      }
    },

    triggerToast({ commit }, payload) {
      commit('showToast', payload)
      if (payload.type !== 'info') {
        setTimeout(() => {
          commit('hideToast')
        }, 5000)
      }
    },

    async initializeAuth({ commit }) {
      commit('setSessionSigningIn', true)

      auth.onAuthStateChanged(async (user) => {
        if (user) {
          // User is signed in (anonymously or not)
          commit('setAuthUser', user)
          try {
            // Attempt to fetch user profile data
            const userProfileSnapshot = await usersCollection
              .doc(user.uid)
              .get()
            if (userProfileSnapshot.exists) {
              commit('setUserProfile', userProfileSnapshot.data())
            }
          } catch (error) {
            console.error('Error fetching user profile:', error)
          }
        } else {
          // No user signed in, proceed with anonymous authentication
          try {
            await auth.signInAnonymously()
            console.log('Signed in anonymously')
          } catch (error) {
            console.error('Error during anonymous sign-in:', error)
          }
        }
        commit('setSessionSigningIn', false)
      })
    },

    /*
    Proceed to user registration with given email address. The code verify if user is from the same device and re-sign if needed then recursively retries.
    */
    async signUp({ commit }, form) {
      logger.debug('PrelaunchSignUp', form)

      commit('setSessionSigningIn', true)
      try {
        var user = await fb.hardProcessSignup(
          form.email,
          form.password,
          form.firstName,
          form.lastName,
          form.option
        )
      } catch (error) {
        commit('setSessionSigningIn', false)
        commit('setUserError', error)
        throw error
      }

      if (form.option !== 'free') {
        commit('setPaymentAwaiting', true)
      } else {
        commit('setPaymentDone', true)
      }

      commit('setSessionSigningIn', false)

      return user
    },

    async paymentCheckout({ commit, dispatch }, form) {
      try {
        const url = await fb.processCheckout(form.user.email, form.option)

        if (form.option !== 'free') {
          return url
        }

        return
      } catch (error) {
        commit('setUserError', error)
        throw error
      } finally {
        commit('setSessionSigningIn', false)
      }
    },

    async paymentWaiting({ commit, dispatch, state }, data) {
      commit('setUserCheckoutLink', data.url)
      window.location.href = data.url
      //window.open(data.url, '_blank') // First open new tab while waiting on /payments collection
      // Creates listener on /payments and listen for a payment that succeeded for the selected product
      const waiting = async () => {
        return new Promise((resolve, reject) => {
          fb.usersCollection.doc(data.user.uid).onSnapshot((snapshot) => {
            const user = snapshot.data()
            if (user.checkout) {
              if (user.checkout.pending) {
                logger.debug(
                  'Waiting while /checkout.pending',
                  user.checkout.pending
                )
                commit('setPaymentPending', true)
              } else if (user.checkout.done) {
                commit('setPaymentDone', true)
                logger.debug('Checkout done at', user.checkout.paidAt)
                resolve()
              } else {
                commit('setPaymentDone', false)
                reject(i18n.t('errors.unknown-error'))
              }
            } else {
              logger.debug('Waiting for /checkout node')
            }
          })
        })
      }

      try {
        return await waiting()
      } catch (error) {
        throw error
      }
    },

    /*
   Proceed to user login with given email address.
   */
    async signIn({ commit, state, dispatch }, form) {
      commit('setSessionSigningIn', true)
      try {
        let anonymousCharactersList = null
        if (
          state.userSession.profile &&
          state.userSession.profile.charactersList &&
          state.userSession.profile.charactersList.length >= 0
        ) {
          anonymousCharactersList = state.userSession.profile.charactersList
        }

        const userCredential = await fb.auth.signInWithEmailAndPassword(
          form.email,
          form.password
        )

        const user = userCredential.user

        const userProfileSnapshot = await fb.usersCollection.doc(user.uid).get()
        if (userProfileSnapshot.exists) {
          commit('setUserProfile', userProfileSnapshot.data())
          commit('setAuthUser', user)

          // Ajout du flag signInSuccessful
          commit('setSignInSuccessful', true)

          // Set user ID in Firebase Analytics
          if (analytics) {
            analytics.setUserId(user.uid)
            analytics.setUserProperties({ userEmail: user.email }) // Send user email as user property
            analytics.logEvent('login', { method: 'email' })
          }

          if (
            anonymousCharactersList != null &&
            anonymousCharactersList.length >= 0
          ) {
            anonymousCharactersList.forEach((character) => {
              dispatch('addCharacterToList', character)
            })
          }

          console.debug(
            'LastActiveCharacter:',
            state.userSession.profile.lastActiveCharacter
          )

          if (state.userSession.profile) {
            console.debug('Profile exists')
            if (state.userSession.profile.charactersList) {
              console.debug('CharactersList exists')
              if (
                state.userSession.profile.lastActiveCharacter !== undefined &&
                state.userSession.profile.charactersList[
                  state.userSession.profile.lastActiveCharacter
                ]
              ) {
                console.debug('LastActiveCharacter exists and valid')
                const lastActiveCharacter =
                  state.userSession.profile.charactersList[
                    state.userSession.profile.lastActiveCharacter
                  ]
                const characterForStorage = JSON.parse(
                  JSON.stringify(lastActiveCharacter)
                )
                localStorage.setItem(
                  'reader',
                  JSON.stringify(characterForStorage)
                )
              } else {
                console.debug(
                  'LastActiveCharacter is not valid:',
                  state.userSession.profile.lastActiveCharacter
                )
              }
            } else {
              console.debug(
                'CharactersList does not exist or is null:',
                state.userSession.profile.charactersList
              )
            }
          } else {
            console.debug(
              'Profile does not exist or is null:',
              state.userSession.profile
            )
          }
        }
      } catch (error) {
        commit('setUserError', error)
        console.error('Error signing in:', error)
        throw error
      } finally {
        commit('setSessionSigningIn', false)
      }
    },

    resetSignInFlag({ commit }) {
      commit('setSignInSuccessful', false)
    },

    async loginWithUid({ dispatch }, uid) {
      const token = await fb.getToken(uid)
      const { authUser } = await fb.auth.signInWithCustomToken(token)
    },

    async logout({ commit, state }) {
      if (state.unsubscribeFunction) {
        state.unsubscribeFunction()
        commit('setUnsubscribeFunction', null)
      }
      await fb.auth.signOut()
      commit('setUserProfile', null)
      await fb.auth.signInAnonymously()
    },

    async requestResetPassword({ commit }, form) {
      fb.auth
        .sendPasswordResetEmail(form.email)
        .then(() => {
          console.debug('Reset password email sent!')
        })
        .catch((error) => {
          console.error(
            'Error while sending reset password email: ' + error.message
          )
          throw error
        })
    },

    // In store/index.js, modify the "confirmResetPassword" action as follows:

    async confirmResetPassword({ commit }, form) {
      try {
        // Attempt to confirm password reset using the provided oobCode and new password.
        await fb.auth.confirmPasswordReset(form.oobCode, form.newPassword)
        // Return a success object to let the component know the reset was successful.
        return { success: true }
      } catch (error) {
        console.error('Error while resetting password:', error)
        // Throw an error object containing the error code so the component can handle translations.
        throw { code: error.code, message: error.message }
      }
    },
    async checkEmailAlreadyExists({ commit }, email) {
      try {
        const signInMethods = await fb.auth.fetchSignInMethodsForEmail(email)
        if (signInMethods.length > 0) {
          return { exists: true }
        }
        return { exists: false }
      } catch (error) {
        console.error('Error while checking email existence:', error)
        commit('setUserError', error.message)
        throw error
      }
    },

    async updateUserProfile({ commit, state }, userData) {
      if (!state.userSession.auth.record) {
        console.error('No authenticated user')
        return
      }

      const user = fb.auth.currentUser

      try {
        if (userData.email && user.email !== userData.email) {
          await user.updateEmail(userData.email)
        }
        const userId = state.userSession.auth.record.uid
        // On met à jour Firestore avec userData
        await fb.usersCollection.doc(userId).update(userData)
        // On merge les nouvelles données avec le profil existant
        const mergedProfile = { ...state.userSession.profile, ...userData }
        commit('setUserProfile', mergedProfile)
      } catch (error) {
        console.error('Error updating user profile:', error)
        commit('setUserError', error.message)
        throw error
      }
    },
    async updatePassword({ commit, state }, formData) {
      if (!state.userSession.auth.record) {
        console.error('No authenticated user was found')
        commit('setUserError', 'No authenticated user was found')
        return
      }

      try {
        await fb.updatePassword(formData.oldPassword, formData.password)
        console.debug('User password was updated successfully')
      } catch (error) {
        console.error('Error while updating password:', error)
        commit('setUserError', error.message)
        throw error
      }
    },

    async getPortalLink({ commit }, uid) {
      try {
        const { url } = await fb.createPortalLink(uid)
        console.log('getPortalLink', url)
        commit('setPortalLink', url)
        return url
      } catch (error) {
        commit('setUserError', error.message)
        throw error
      }
    },

    async unsubscribeUser({ commit }, uid) {
      try {
        const { redirect } = await fb.unsubscribeUser(uid)
        console.log('unsubscribeUser')
        return redirect
      } catch (error) {
        commit('setUserError', error.message)
        throw error
      }
    },

    async addCharacterToList({ commit, state }, character) {
      console.debug('Adding character to list')
      const uid = state.userSession.auth.record.uid
      if (!uid) {
        const error = new Error('User is not authenticated.')
        commit('setUserError', error.message)
        throw error
      }

      try {
        const characterObj =
          typeof character === 'string' ? JSON.parse(character) : character
        await fb.addCharacterToUserList(uid, characterObj)
        await this.dispatch('loadUserProfile')
      } catch (error) {
        console.error('Error adding character:', error.message)
        commit('setUserError', error.message)
        throw error
      }
    },

    async updateCharacterInList(
      { commit, state, dispatch },
      { characterIndex, updatedCharacter }
    ) {
      const uid = state.userSession.auth.record?.uid
      if (uid) {
        await fb.updateCharacterInUserList(
          uid,
          characterIndex,
          updatedCharacter
        )
        await dispatch('loadUserProfile')

        const lastActiveCharacter =
          state.userSession.profile.charactersList[
            state.userSession.profile.lastActiveCharacter
          ]
        localStorage.setItem('reader', JSON.stringify(lastActiveCharacter))
      } else {
        console.error('UID was not found. Impossible to update character.')
      }
    },

    async deleteCharacterFromList({ commit, state, dispatch }, characterIndex) {
      const uid = state.userSession.auth.record?.uid
      if (
        !uid ||
        !state.userSession.profile.charactersList ||
        state.userSession.profile.charactersList.length <= 1
      ) {
        console.error('Cannot delete the only character.')
        return
      }

      await fb.deleteCharacterFromUserList(uid, characterIndex)

      // Picking a new lastActiveCharacter
      let newLastActiveCharacterIndex =
        characterIndex === 0 ? 0 : characterIndex - 1

      await dispatch('setLastActiveCharacter', newLastActiveCharacterIndex)
      await dispatch('loadUserProfile')

      const lastActiveCharacter =
        state.userSession.profile.charactersList[newLastActiveCharacterIndex]
      localStorage.setItem('reader', JSON.stringify(lastActiveCharacter))
    },

    async setLastActiveCharacter(
      { commit, state, dispatch },
      lastActiveCharacterIndex
    ) {
      const uid = state.userSession.auth.record?.uid
      if (!uid) {
        console.error('No user ID found')
        return
      }
      await fb.updateLastActiveCharacter(uid, lastActiveCharacterIndex)
      commit('setLastActiveCharacter', lastActiveCharacterIndex)

      await dispatch('loadUserProfile')

      const lastActiveCharacter =
        state.userSession.profile.charactersList[lastActiveCharacterIndex]
      localStorage.setItem('reader', JSON.stringify(lastActiveCharacter))
    },

    async fetchUniversesList({ commit }, locale) {
      commit('setStoriesListLoading', true)
      try {
        const universes = await fb.getUniversesList(locale)
        commit('setStoriesListLoading', false)
        return universes
      } catch (error) {
        console.error('Error fetching universes list:', error)
        commit('setUserError', error.message)
        commit('setStoriesListLoading', false)
        throw error
      }
    },

    async fetchStoryMetaData({ commit }, { locale, storyId }) {
      try {
        const universes = await fb.getStoryMetaData(storyId, locale)
        return universes
      } catch (error) {
        console.error('Error fetching story meta data:', error)
        commit('setUserError', error.message)
        throw error
      }
    },

    updateNetworkStatus({ commit }, status) {
      commit('setNetworkStatus', status)
    },

    async fetchUser() {
      const user = fb.auth.currentUser
      if (user) {
        this.user = user
        return user
      } else {
        // Gérer le cas où l'utilisateur n'est pas connecté
        throw new Error('No user found')
      }
    },

    async deleteUser({ commit, state }) {
      const uid = state.userSession.auth.record?.uid
      await fb.deleteUser(uid)
    },
  },

  getters: {
    isOnline: (state) => state.isOnline,
    isSignInSuccessful: (state) => state.userSession.auth.signInSuccessful,
  },
})

export default store
